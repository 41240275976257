//Basic styles
@import url('https://fonts.googleapis.com/css?family=Roboto|Slabo+27px&subset=cyrillic,cyrillic-ext');
@import "include-media";

* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  background: #9d00ba;
  height: 100%;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  position: relative;
}

.github-fork-ribbon {
  &:before {
    background-color: #660078;
  }

  /**
   * Ban IE
   */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: none;
  }
  display: none \9
;

  @include media("<900px") {
    display: none;
  }
}

.explanation {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  max-width: 500px;
  text-align: center;
  font-weight: bold;
  font-size: 32px;
  opacity: 0.3;
  color: #fff;

  /**
   * Ban IE
   */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: none;
  }
  display: none \9
;

  @include media("<900px") {
    display: none;
  }
}

.container {
  position: relative;
  perspective: 1000px;
  height: 100%;
}

//Notebook
.notebook {
  position: absolute;
  top: 50%;
  left: 50%;
  transform-style: preserve-3d;
  transform: translateX(-50%) translateY(-50%);

  .board {
    position: absolute;
    border-radius: 10px;
    background: #333;
    transform-origin: top left;
    transform: rotateX(60deg);
    box-sizing: border-box;
    padding: 10px 5px;
    border: 6px solid #222;
    .system {
      padding-bottom: 10px;
      text-align: right;

      a {
        color: #fff;
        font-size: 16px;
        height: 30px;
        line-height: 30px;
      }
    }
    .row {
      padding-bottom: 8px;
      text-align: center;
    }
    button {
      width: 40px;
      height: 50px;
      font-size: 25px;
      background: #444;
      border: none;
      color: #fff;
      &:hover,
      &:focus {
        background: #555;
        cursor: pointer;
        outline: none;
      }
    }
  }

  .screen {
    position: absolute;
    box-sizing: border-box;
    transform-origin: bottom left;
    transform: rotateX(-120deg) translateY(-6px);
    transform-style: preserve-3d;

    .screen-back {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: #222;
      transform: rotateX(180deg);
      backface-visibility: hidden;
      border-radius: 10px;
      box-sizing: border-box;
      cursor: pointer;
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
      z-index: 1;

      h1 {
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        color: #444;
        font-family: 'Slabo 27px', serif;
        font-size: 36px;

        small {
          display: block;
          font-size: 0.5em;
        }
      }
    }

    .screen-front {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: #059fff;
      backface-visibility: hidden;
      border: 10px solid #222;
      border-radius: 10px;
      box-sizing: border-box;
      transform-style: preserve-3d;
    }
  }

  .joints {
    position: absolute;
    width: 100%;
    > div {
      height: 100%;
      position: absolute;
      border-radius: 2px;
      box-sizing: border-box;
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#222222+0,222222+40,252525+100 */
      background: #222222; /* Old browsers */
      background: -moz-linear-gradient(top, #222222 0%, #222222 40%, #252525 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, #222222 0%, #222222 40%, #252525 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, #222222 0%, #222222 40%, #252525 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#222222', endColorstr='#252525', GradientType=0); /* IE6-9 */
    }
  }

  .license-and-source {
    font-size: 0.6em;
    line-height: 1.5em;
    text-align: center;
    position: absolute;
    bottom: 15px;
    width: 100%;

    a {
      color: #fff;
    }
  }
}

//Screen sizes and old browsers
.old-browser {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  max-width: 800px;
  text-align: center;
  font-weight: bold;
  font-size: 32px;
  color: #fff;

  .header, .subheader, .browser-icons a {
    opacity: 0.3;
  }

  .subheader {
    padding-top: 1em;
    font-size: 0.5em;
  }

  .browser-icons {
    padding-top: 0.5em;
    font-size: 5em;

    a, a:visited, a:active {
      color: inherit;
      transition: opacity 0.5s;
    }

    a:hover {
      opacity: 0.7;
    }
  }

  display: none;
  display: block \9
;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: block;
  }
}

body {
  @include media(">=900px", "height<600px") {
    overflow: auto;
  }

  @include media(">=1280px", "height<800px") {
    overflow: auto;
  }
}

.container {
  display: none;

  @include media(">=900px") {
    display: block;
  }

  @include media(">=900px", "height<600px") {
    height: auto;
    min-height: 600px;
  }

  @include media(">=1280px", "height<800px") {
    height: auto;
    min-height: 800px;
  }

  /**
   * Ban IE
   */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: none;
  }
  display: none \9
;
}

.notebook {
  width: 900px;
  height: 600px;

  @include media(">=1280px") {
    width: 1280px;
    height: 800px;
  }

  .board, .screen {
    width: 550px;
    height: 310px;
    left: 175px;

    @include media(">=1280px") {
      width: 700px;
      height: 394px;
      left: 290px;
    }
  }

  .board {
    top: 326px;

    @include media(">=1280px") {
      top: 428px;

      .system {
        padding-right: 16px;
      }

      button {
        width: 50px;
      }
    }
  }

  .screen {
    bottom: 280px;

    @include media(">=1280px") {
      bottom: 380px;
    }
  }

  .joints {
    top: 320px;
    height: 6px;

    @include media(">=1280px") {
      top: 420px;
      height: 8px;
    }

    > div {
      width: 50px;

      @include media(">=1280px") {
        width: 70px;
      }

      &.left {
        left: 200px;

        @include media(">=1280px") {
          left: 320px;
        }
      }
      &.right {
        right: 200px;

        @include media(">=1280px") {
          right: 320px;
        }
      }
    }
  }

  .license-and-source {
    @include media("height>800px") {
      bottom: 0;
    }

    @include media("height>850px") {
      bottom: -15px;
    }
  }
}

.small-device {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  max-width: 500px;
  text-align: center;
  font-weight: bold;
  font-size: 32px;
  opacity: 0.3;
  color: #fff;

  @include media(">=900px") {
    display: none;
  }
}

//Content
.notebook {
  .screen {
    .screen-front {
      .screen-content {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        overflow: hidden;

        .loading-screen, .login-screen {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
        }

        .loading-screen {
          background: #0061ff;
          transform-style: preserve-3d;
          transform: translateX(-100%);

          .spinner {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            color: #fff;

            i {
              opacity: 0;
            }
          }
        }

        .login-screen {
          background: #007eff;
          color: #fff;
          transform-style: preserve-3d;

          .login-form {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            text-align: center;
            padding: 20px;
            background: rgba(0, 0, 0, 0.1);
            box-sizing: border-box;
            width: 200px;

            .row {
              padding-bottom: 10px;

              &.lock {
                font-size: 30px;
              }

              &.action {
                padding-top: 10px;
              }

              label {
                display: block;
                margin-bottom: 5px;
              }

              input {
                border: none;
                outline: none;
                display: block;
                width: 100%;
                height: 20px;
                line-height: 20px;
                color: #000;
                text-align: center;
              }

              button {
                display: block;
                width: 100%;
                background: none;
                border: 1px solid #fff;
                border-radius: 3px;
                box-shadow: none;
                outline: none;
                font-size: 16px;
                font-weight: bold;
                height: 30px;
                line-height: 30px;
                color: #fff;
                padding: 0;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}

//Animations
$openAnimationTime: 1.5s;
$loadingAnimationTime: 1.5s;
$formAnimationDelay: 500ms;
$formAnimationTime: 1s;

.notebook {
  &.open {
    .screen {
      transform: rotateX(0deg) translateY(0);
      animation: open $openAnimationTime 1 linear;

      .screen-content {
        .loading-screen {
          transform: translateX(0);
          animation: hide-loading-screen $loadingAnimationTime 1 linear;
          animation-delay: $openAnimationTime;
          animation-fill-mode: forwards;

          .spinner {
            i {
              opacity: 0;
              animation: spinner $loadingAnimationTime 1 linear;
              animation-delay: $openAnimationTime;
              animation-fill-mode: forwards;
            }
          }
        }

        .login-screen {
          .login-form {
            top: 100%;
            transform: translateX(-50%) translateY(0);
            animation: show-login-form $formAnimationTime 1 ease-out;
            animation-delay: $openAnimationTime + $loadingAnimationTime + $formAnimationDelay;
            animation-fill-mode: forwards;
          }
        }
      }
    }
  }

  &.close {
    .screen {
      transform: rotateX(-120deg) translateY(-6px);
      animation: close $openAnimationTime 1 linear;
    }
  }
}

@keyframes open {
  //Open notebook animation

  0% {
    transform: rotateX(-120deg) translateY(-6px);
  }

  //This stop is needed to smoothly adjust translateY transform
  //which should be -6px for closed notebook, but 0 for open (this requirement caused by having a joints)
  30% {
    transform: rotateX(-84deg) translateY(0)
  }

  100% {
    transform: rotateX(0deg) translateY(0);
  }
}

@keyframes hide-loading-screen {
  //Hide loading screen. This animation starts on 50%, when spinner stops to spin and hides

  0%, 50% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes spinner {
  //Loading screen's spinner animation. It stops on 50%, after that loading screen starts to slide to the left side

  0% {
    transform: rotateZ(0deg);
    opacity: 1;
  }

  45% {
    transform: rotateZ(180deg);
    opacity: 1;
  }

  50%, 100% {
    transform: rotateZ(180deg);
    opacity: 0;
  }
}

@keyframes show-login-form {
  //Show login form animation - slide from bottom

  0% {
    top: 100%;
    transform: translateX(-50%) translateY(0);
  }

  100% {
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

@keyframes close {
  //Closing notebook animation

  0% {
    transform: rotateX(0deg);
  }

  //This stop is needed to smoothly adjust translateY transform
  //which should be -6px for closed notebook (this requirement caused by having a joints)
  70% {
    transform: rotateX(-84deg) translateY(0);
  }

  100% {
    transform: rotateX(-120deg) translateY(-6px);
  }
}

.fa-spin-3d {
  animation: fa-spin-3d 2s infinite linear;
}

@keyframes fa-spin-3d {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(359deg);
  }
}